import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { isNumber } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  errFileds: any = [];

  name = [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(30),
    Validators.pattern('^[a-zA-Z- _]+$'),
  ];
  nameNotRequire = [
    Validators.minLength(3),
    Validators.maxLength(30),
    Validators.pattern('^[a-zA-Z- _]+$'),
  ];
  email = [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
  ];
  emailNotRequire = [
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
  ];
  phone = [
    Validators.required,
    Validators.minLength(10),
    Validators.pattern('^[0-9]+$')
  ];
  phoneNotRequire = [
    Validators.maxLength(20),
    Validators.pattern('^[0-9]+$')
  ];
  username = [
    Validators.required,
    Validators.maxLength(20),
    Validators.pattern('')
  ];
  password = [
    Validators.required,
    Validators.minLength(8),
  ];
  passwordNotRequired = [
    Validators.minLength(8),
  ];
  text = [
    Validators.pattern(''),
    Validators.maxLength(255),
  ];
  number = [
    Validators.pattern('^[0-9]+$')
  ];
  letter = [
    Validators.pattern('^[a-zA-Z\s]+$')
  ];
  required = [
    Validators.required
  ];

  setErrorsFiled(InvalidFileds: any[]): void {
    this.errFileds = [];
    for (const el of InvalidFileds) {
      this.errFileds.push({ field: el.field, err: el.message });
    }
  }

  checkFiled(field: string): boolean {
    for (const el of this.errFileds) {
      if (el.field === field) {
        return true;
      }
    }
    return false;
  }

  getErrorMessage(field: string): string {
    for (const el of this.errFileds) {
      if (el.field === field) {
        return el.reason;
      }
    }
    return '';
  }

  IsRequired(validations: any): boolean {
    if (validations) {
      for (const el of validations) {
        if (el === Validators.required) {
          return true;
        }
      }
    }
    return false;
  }

  StringToNumber(value: any): number {
    value = value?.replaceAll(',', '');
    return Number(value);
  }

  NumToCurrency(value: any): any {
    if (!isNumber(value)) {
      if (value?.includes(',')) {
        value = this.StringToNumber(value);
      }
    }
    return new Intl.NumberFormat().format(Number(value));
  }

  ConvertNumberForm(dataForm: any): any {
    dataForm.get('exchange_amount')?.setValue(this.StringToNumber(dataForm.get('exchange_amount')?.value));
    dataForm.get('exchange_rate')?.setValue(this.StringToNumber(dataForm.get('exchange_rate')?.value));
    dataForm.get('amount')?.setValue(this.StringToNumber(dataForm.get('amount')?.value));
    return dataForm;
  }

  ConvertNumber(strNumber: string): any {
    var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
    var en = '0123456789'.split('');

    var indexDot = strNumber?.indexOf(".");
    if (indexDot === 0) {
      strNumber = strNumber?.replace('.', '')
    }

    if ((strNumber.match(/\./g) || []).length > 1) {
      strNumber = strNumber?.replace('.', '')
    }

    var indexDot = strNumber?.indexOf("-");
    if (indexDot !== 0) {
      strNumber = strNumber?.replace('-', '')
    }

    if ((strNumber.match(/\-/g) || []).length > 1) {
      strNumber = strNumber?.replace('-', '')
    }

    if (strNumber?.includes('.-')) {
      strNumber = strNumber?.replace('.-', '')
    }

    if (strNumber?.includes('-.')) {
      strNumber = strNumber?.replace('-.', '')
    }

    let text = strNumber?.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x) => en[ar.indexOf(x)]);

    text = text?.replace(/[^\.?\-?|\d]/g, '');

    let number: number = +text

    text = text?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return { text, number }
  }


}
