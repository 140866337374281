export const environment = {
  production: true,
  apiUrl: '/api/ces/v1',
  snackBarDuration: 8000,
  logo: "barham",
  isCompany: true,
  autoPrint: false,
  arabicTerms: false,
  cache: false,
  theme: "theme1",
  localStorageKey: 'ces-barham',
  appName: "barham",
  defaultCurrencyID: 1,
  defaultAccount: 22,
  companySlogan: "For Money Exchange",
  companyDetail: "کۆمپانیای بەرهەم بۆ ئاڵوگۆڕی دراو",
  companyAddress: "",
  companyPhones: "0770 359 5031 - 0750 121 9199",
  phoneRight1: "0770 359 5031",
  phoneRight2: "",
  phoneRight3: "",
  phoneRight4: "",
  phoneLeft1: "0750 121 9199",
  phoneLeft2: "",
  phoneLeft3: "",
  phoneLeft4: "",
  iqd: true,
  irr: true,
  euro: true,
  pound: true,
  try: false,
  aed: false,
};
