import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { MessageService } from './@core/services/message.service';
import { ThemeService } from './@core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  Title = environment.appName;

  constructor(
    private messageService: MessageService,
    private snackBar: MatSnackBar,
    private themes: ThemeService,
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
  ) {
    // get saved theme from localStorage
    // const StorageTheme = localStorage.getItem('theme');
    // this.themes.addTheme(StorageTheme);

    this.themes.addTheme(environment.theme);

    // init svg icons
    this.initSvgIcons();

    this.messageService.message$.subscribe(
      (m) => {
        this.snackBar.open(m.message, '🆇', {
          duration: m.type === 'success' ? 3000 : environment.snackBarDuration,
          verticalPosition: 'bottom',
          horizontalPosition: 'center',
          panelClass: [m.type],
        });
      }
    );
  }

  // definitions to svg icons
  initSvgIcons(): void {
    this.matIconRegistry.addSvgIcon('logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/logout.svg'));
    this.matIconRegistry.addSvgIcon('excel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/excel.svg'));

    this.matIconRegistry.addSvgIcon('usd',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/usd.svg'));
    this.matIconRegistry.addSvgIcon('irr',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/irr.svg'));
    this.matIconRegistry.addSvgIcon('iqd',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/iqd.svg'));
    this.matIconRegistry.addSvgIcon('euro',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/euro.svg'));
    this.matIconRegistry.addSvgIcon('pound',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/pound.svg'));
    this.matIconRegistry.addSvgIcon('try',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/try.svg'));
    this.matIconRegistry.addSvgIcon('aed',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/aed.svg'));

    this.matIconRegistry.addSvgIcon('irr-usd',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/irr-usd.svg'));
    this.matIconRegistry.addSvgIcon('iqd-usd',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/iqd-usd.svg'));
  }

}
